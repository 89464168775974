import {
  ResponseAvailableCampaignTypes,
  ResponseCampaignRunningConditions,
  ResponseCampaignToArchive,
  ResponseClient,
  ResponseGetIABCategories,
  ResponseShop,
  ResponseShopFeeds,
  ResponseStudioTemplates,
  ResponseUserClients,
} from '@ads/front-core/api'
import { Campaign, ClientBalance } from '@ads/front-core/interfaces'
import { RootState, preloadedRootState } from '@ads/front-core/store/rootState'
import { SidebarProps } from '@ads/front-core/components/SidebarFixed'
import { SegmentsStore } from 'components/Targeting/TargetSegments/interfaces'
import { IForm as ICreationForm } from 'components/views/Creations/RichMedia'
import { IForm as INewsletterForm } from 'components/views/Newsletter/Creator/interfaces'

export interface AppState extends RootState {
  shop: Record<number, ResponseShop>
  feeds: {
    isLoading: boolean
    list?: ResponseShopFeeds
  }
  client: {
    currentClientId: number
    currentShopId: number
    balance?: ClientBalance
    list: ResponseUserClients
    currentClient?: ResponseClient
    currentClientShops?: ResponseShop[]
  }
  campaign: {
    availableCampaignTypes: Record<number, ResponseAvailableCampaignTypes>
    availableCampaignTypesLoading: boolean
    campaignsToArchive?: ResponseCampaignToArchive[]
    campaign?: Campaign
    campaignSegments?: SegmentsStore
    campaignIAB?: ResponseGetIABCategories
    runningConditions: Record<string, ResponseCampaignRunningConditions>
    runCampaignAfterSave?: boolean
  }
  common: {
    isOpenLeadModal: boolean
    isOpenPromotion1000Rules: boolean
    isOpenPromotion1000About: boolean
    newsletterListUrl?: string
    newsletterPreviewTemplate?: ResponseStudioTemplates[number]['template']
    forceSelectTab?: SidebarProps['selectedTab']
  }
  draft: {
    campaign: Campaign
    newsletter: INewsletterForm
    creation: ICreationForm
  }
}

export const preloadedState: AppState = {
  ...preloadedRootState,
  shop: {},
  feeds: {
    isLoading: false,
    list: undefined,
  },
  client: {
    currentClientId: 0,
    currentShopId: 0,
    list: [],
    balance: {
      blockedFunds: 0,
      monthLimit: 0,
      monthlyBalance: 0,
      balance: 0,
      isLoading: true,
    },
    currentClient: undefined,
  },
  campaign: {
    availableCampaignTypes: {},
    availableCampaignTypesLoading: false,
    runningConditions: {},
  },
  draft: {
    campaign: undefined,
    newsletter: undefined,
    creation: undefined,
  },
  common: {
    isOpenLeadModal: false,
    isOpenPromotion1000Rules: false,
    isOpenPromotion1000About: false,
  },
}
