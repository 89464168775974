/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestCreateMailingTransactional } from '../models/RequestCreateMailingTransactional'
import type { RequestGenerateToken } from '../models/RequestGenerateToken'
import type { RequestUpdateMailingTransactional } from '../models/RequestUpdateMailingTransactional'
import type { ResponseEntityCreated } from '../models/ResponseEntityCreated'
import type { ResponseMailingTransactional } from '../models/ResponseMailingTransactional'
import type { ResponseMailingTransactionalList } from '../models/ResponseMailingTransactionalList'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class MailingTransactionalService {
  /**
   * Modyfikuje MailingTransactional
   *
   * @returns Error unexpected error
   * @returns any MailingTransactional zmodyfikowane
   * @throws ApiError
   */
  public static updateMailingTransactional({
    mailingTransactionalId,
    requestBody,
  }: {
    mailingTransactionalId: number
    /**
     * Dane mailingu transakcyjnego
     */
    requestBody: RequestUpdateMailingTransactional
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mailing-transactional/{mailingTransactionalId}',
      path: {
        mailingTransactionalId: mailingTransactionalId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca MailingTransactional na podstawie ID
   *
   * @returns ResponseMailingTransactional dane MailingTransactional
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getMailingTransactional({
    mailingTransactionalId,
  }: {
    mailingTransactionalId: number
  }): CancelablePromise<ResponseMailingTransactional | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mailing-transactional/{mailingTransactionalId}',
      path: {
        mailingTransactionalId: mailingTransactionalId,
      },
    })
  }

  /**
   * Tworzy MailingTransactional
   *
   * @returns Error unexpected error
   * @returns ResponseEntityCreated MailingTransactional utworzone
   * @throws ApiError
   */
  public static createMailingTransactional({
    requestBody,
  }: {
    /**
     * Dane mailingu transakcyjnego
     */
    requestBody: RequestCreateMailingTransactional
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mailing-transactional/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca listę MailingTransactional
   *
   * @returns ResponseMailingTransactionalList dane MailingTransactional
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static listMailingTransactional({
    shopId,
    limit,
    page,
  }: {
    shopId: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * numer strony do pobrania
     */
    page: number
  }): CancelablePromise<ResponseMailingTransactionalList | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mailing-transactional/',
      query: {
        shopId: shopId,
        limit: limit,
        page: page,
      },
    })
  }

  /**
   * Generuje nowy token uwierzytelniający
   *
   * @returns Error unexpected error
   * @returns any token wygenerowany
   * @throws ApiError
   */
  public static generateToken({
    requestBody,
  }: {
    /**
     * Dane mailingu transakcyjnego
     */
    requestBody: RequestGenerateToken
  }): CancelablePromise<
    | Error
    | {
        token?: string
      }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mailing-transactional/generate-token/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
