import React, { FC, MouseEvent, ReactElement, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Box, Flex, IconButton, Text } from '@ads/front-ds'
import { Button, Link, TextButton } from '@ads/front-core/atoms'
import HorizontalLine from '@ads/front-core/components/HorizontalLine'
import zIndex from '@ads/front-core/constants/zIndex'
import { getScrollbarStyles } from '@ads/front-core/utils/scrollbar'

export type DialogLargeActions = {
  label: string
  disabled?: boolean
  variant: 'cta' | 'primary' | 'secondary' | 'destructive' | 'text'
  to?: string
  onClick?: (e?: MouseEvent) => void
}[]

interface IDialogLarge {
  title: string | ReactElement
  titleSufix?: string | ReactElement
  actions: DialogLargeActions
  additionalFooterContent?: string | React.ReactElement
  maxWidth?: number
  onClose: () => void
  children?: ReactNode
}

export const DialogLarge: FC<IDialogLarge> = ({
  title,
  titleSufix,
  actions,
  additionalFooterContent,
  maxWidth,
  onClose,
  children,
}) => (
  <Container>
    <Dialog maxWidth={maxWidth}>
      <Header>
        <Flex gap="s">
          <Text fontSize="xl" fontWeight="medium">
            {title}
          </Text>
          {titleSufix && (
            <Text fontSize="xl" fontWeight="medium">
              {titleSufix}
            </Text>
          )}
        </Flex>
        <CloseButton name="20-close" onClick={onClose} />
      </Header>
      <Box>
        <Body pt="l" px="l">
          {children}
        </Body>
        <Box mx="l">
          <HorizontalLine mt="xs" mb="s" />
          <Flex
            justifyContent={
              additionalFooterContent ? 'space-between' : 'flex-end'
            }
            mb="s"
          >
            {additionalFooterContent && (
              <Box styleCss={{ flex: 1 }}>{additionalFooterContent}</Box>
            )}

            <Flex
              gap="xs"
              justifyContent="flex-end"
              alignItems="center"
              styleCss={{ flex: 1 }}
            >
              {actions.map(action => (
                <React.Fragment key={action.label}>
                  {action.to ? (
                    <Link to={action.to}>
                      {action.variant === 'text' ? (
                        <TextButton disabled={action.disabled}>
                          {action.label}
                        </TextButton>
                      ) : (
                        <Button
                          variant={action.variant}
                          disabled={action.disabled}
                        >
                          {action.label}
                        </Button>
                      )}
                    </Link>
                  ) : (
                    <>
                      {action.variant === 'text' ? (
                        <TextButton
                          disabled={action.disabled}
                          onClick={action.onClick}
                        >
                          {action.label}
                        </TextButton>
                      ) : (
                        <Button
                          variant={action.variant}
                          disabled={action.disabled}
                          onClick={action.onClick}
                        >
                          {action.label}
                        </Button>
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Dialog>
  </Container>
)

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 40, 0.2);
  z-index: ${zIndex.DIALOG};
`

const Dialog = styled.div<Pick<IDialogLarge, 'maxWidth'>>`
  ${p => css`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 737px;
    background-color: ${p.theme.colors.inverted100};

    ${p.maxWidth &&
    css`
      width: 100%;
      max-width: ${p.maxWidth}px;
    `}
  `}
`

const Header = styled.div`
  ${p => css`
    display: flex;
    justify-content: space-between;
    height: 52px;
    padding: ${p.theme.space.s}px;
    color: ${p.theme.colors.inverted100};
    background-color: #262a44;
  `}
`

const Body = styled(Box)`
  max-height: calc(100vh - 264px);
  overflow-y: auto;

  ${getScrollbarStyles()}
`

const CloseButton = styled(IconButton)`
  ${p => css`
    color: ${p.theme.colors.inverted100};

    &:hover {
      color: ${p.theme.colors.inverted100};
      background-color: transparent;
    }
  `}
`
