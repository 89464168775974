import { createAction } from '@reduxjs/toolkit'
import { IForm as ICreationForm } from 'components/views/Creations/RichMedia'
import { AppState } from '../../appState'

export const setCreationDraft = createAction<ICreationForm>(
  'draft/setCreationDraft',
)

export const setCreationDraftReducer = (
  state: AppState['draft'],
  action: ReturnType<typeof setCreationDraft>,
): void => {
  state.creation = action.payload
}
