import { StatType, PushStatType } from '@ads/front-core/api'

export type RelativeRange =
  | 'TODAY'
  | 'YESTERDAY'
  | 'THIS_WEEK'
  | 'LAST_7_DAYS'
  | 'THIS_MONTH'
  | 'PREV_MONTH'
  | 'LAST_30_DAYS'
  | 'THIS_YEAR'
  | 'CURRENT_NEWSLETTER'

export enum Dimensions {
  ID = '1',
  NAME = '2',
  STATUS = '3',
  DESCRIPTION = '4',
}

export enum StatDashboard {
  GENERAL,
  MA,
}

export type StatItem = {
  name: string
  statKey:
    | 'cr'
    | 'income'
    | 'clicks'
    | 'transactions'
    | 'aov'
    | 'cost'
    | 'roas'
    | 'cos'
    | 'vimp'
    | 'views'
    | 'ctr'
    | 'vctr'
    | 'vrate'
    | 'ecpc'
    | 'delivery'
    | 'open'
    | 'octr'
    | 'openrate'
    | 'error'
    | 'sent'
  type: 'number' | 'percent' | 'ratio' | 'duration' | 'currency' | 'tableitem'
  defaultHidden?: boolean
  tooltip?: string
  showOnDashboard: StatDashboard[]
}

export type StatItemKey = StatType | PushStatType | 'BUDGET'

export type StatListItem = {
  id: StatItemKey
} & StatItem
