export type ResponseUploaded = {
  status: 'ok' | 'not-ok'
  url?: string
  path?: string
}

export enum Extensions {
  SVG = 'SVG',
  PNG = 'PNG',
  JPG = 'JPG',
  JPEG = 'JPEG',
  GIF = 'GIF',
  ZIP = 'ZIP',
  CSV = 'CSV',
  MP4 = 'MP4',
}

export const MIMEType: Record<Extensions, string[]> = {
  [Extensions.SVG]: ['image/svg+xml'],
  [Extensions.PNG]: ['image/png'],
  [Extensions.JPEG]: ['image/jpeg'],
  [Extensions.JPG]: ['image/jpeg'],
  [Extensions.GIF]: ['image/gif'],
  [Extensions.ZIP]: [
    'application/zip',
    'application/x-zip',
    'application/zip-compressed',
    'application/x-zip-compressed',
  ],
  [Extensions.CSV]: ['text/csv', 'application/vnd.ms-excel'],
  [Extensions.MP4]: ['video/mp4'],
}
